import { FC } from 'react'
import { Flow } from '@src/components/common/flow'
import { Title, List, ListItem } from '@platform/ui'

import { BusinessInfoCardProps } from './types'

export const BusinessInfoCard: FC<BusinessInfoCardProps> = ({ title, itemList, imgSrc }) => {
  return (
    <Flow directory="col" className="shadow-xl rounded-xl h-full" space={4}>
      <Flow directory="col" className="px-8 pt-7">
        <Title color="neutral" colorNumber={900} weight="medium" className="text-sm md:text-md">
          {title}
        </Title>
        <List className="list-inside list-disc marker:primary-500">
          {itemList.map((item, key) => (
            <ListItem className="text-xs md:text-sm text-primary-500" key={key}>
              <span className="text-neutral-900">{item}</span>
            </ListItem>
          ))}
        </List>
      </Flow>
      <Flow align="end" className="h-full">
        <img src={imgSrc} className='w-full'/>
      </Flow>
    </Flow>
  )
}
