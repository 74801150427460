import { FC } from 'react'
import { Flow } from '@src/components/common/flow'
import { Button } from '@platform/ui'

import { PromoProps } from './types'

export const Promo: FC<PromoProps> = ({ onClick, title }) => {
  return (
    <Flow
      directory="col"
      space={8}
      className="bg-primary-500 py-9 md:py-48px px-18px md:px-8 text-center rounded-xl"
      full
      align="center"
    >
      <span className="font-medium text-lg md:text-1xl text-white">{title}</span>
      <Button
        onClick={onClick}
        className="w-full max-w-345px"
        color="white"
        textClassName="text-black font-semibold text-xs md:text-sm"
      >
        Перейти в каталог
      </Button>
    </Flow>
  )
}
