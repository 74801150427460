import { FC } from 'react'
import { Flow } from '@src/components/common/flow'
import { InfoCardProps } from './types'
import { Title } from '@platform/ui'
import { ButtonLink } from '@src/components/common/button-link'

export const InfoCard: FC<InfoCardProps> = ({
  imgSrc,
  title,
  description,
  descriptionClassName,
  pathTo,
  titlePathTo,
  className,
}) => {
  return (
    <Flow directory="col" align="center" space={4} full className={className}>
      <img src={imgSrc} />
      {!!title && (
        <Title color="neutral" colorNumber={900} weight="medium" className="text-sm md:text-md">
          {title}
        </Title>
      )}
      <span className={descriptionClassName}>{description}</span>
      {!!pathTo && !!titlePathTo && <ButtonLink pathTo={pathTo} title={titlePathTo} />}
    </Flow>
  )
}
