import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, Title } from '@platform/ui'
import { models } from '@platform/core'

import { LINKS } from '@src/routes'
import { core, ScreenMode } from '@src/core'
import { Flow } from '@src/components/common/flow'
import { Breadcrumbs } from '@src/components/common/breadcrumbs'
import { CategorySwiper } from '@src/container/category-swiper'
import { SectionH2, Section } from '@src/components/common/section'
import { ProductList } from '@src/components/desktop/product-list'
import { useFilteredProducts } from '@src/hooks/use-filtered-products'
import { useNavigationApp } from '@src/hooks/use-navigation-app'
import { ScrollToTop } from '@src/components/common/scrollToTop'
import { opportunityExchangeMap, stepsWorkExchangeMap } from '@src/pages/home/constants'
import { InfoCard } from '@src/pages/home/components/info-card'
import { Promo } from '@src/pages/home/components/promo'
import { StepsWork } from '@src/pages/home/components/steps-work'

import AuctionLogo from '@src/images/auction-logo.png'

const { layoutService } = core
const { screen } = layoutService
const { ProductType } = models

export const PromoExchange: FC = observer(() => {
  const handleToCatalog = useNavigationApp({ to: LINKS.CATEGORY_HOME })

  const { products, requestStatus } = useFilteredProducts({
    params: {
      typeFlyer: ProductType.EXCHANGE,
      size: 4,
    },
  })

  return (
    <ScrollToTop>
      <Flow directory="col" space={'88px'} className="content-main py-7">
        <Flow>
          <Flow directory="col" space={10.5} className="w-full md:pr-4 lg:pr-0 md:w-1/2" align="start">
            <Breadcrumbs items={[{ children: 'Главная', href: LINKS.HOME }, { children: 'Обмены' }]} />
            <Flow directory="col" space={5}>
              <Title
                tag="h1"
                color="neutral"
                colorNumber={900}
                weight="semibold"
                size={screen.mode === ScreenMode.mobile ? '1xl' : '2xl'}
              >
                Обменивайте ваши товары на товары других продавцов!
              </Title>
              <span className="font-medium text:sm md:text-md">
                Теперь можно не только покупать. Воспользуйтесь каталогом и сделайте своё первое предложение на обмен.
              </span>
            </Flow>
            <Button color="primary" size="xl" className="max-w-345px w-full" onClick={handleToCatalog}>
              Перейти в каталог
            </Button>
          </Flow>
          {screen.mode !== ScreenMode.mobile && (
            <div className="w-1/2">
              <img src={AuctionLogo} alt="Аукцион" />
            </div>
          )}
        </Flow>
        <SectionH2 title="Возможности">
          <Flow className="justify-center md:justify-between flex-wrap gap-y-10 px-2 md:px-8 lg:px-0">
            {opportunityExchangeMap.map((item, index) => (
              <InfoCard
                key={index}
                {...item}
                descriptionClassName="text-xs md:text-sm"
                className="max-w-280px text-center"
              />
            ))}
          </Flow>
        </SectionH2>
        <Section>
          <CategorySwiper customTitle="Найдётся всё что нужно" />
        </Section>
        <SectionH2 title="Как это работает?">
          <StepsWork steps={stepsWorkExchangeMap} />
        </SectionH2>
        <SectionH2 className="py-6" title="Популярные обмены" linkPath={`/category`} linkTitle="Смотреть все обмены">
          <ProductList products={products} requestStatus={requestStatus} />
        </SectionH2>
        <Promo onClick={handleToCatalog} title="Всё ещё думаете? Перейдите в каталог и предложите свой первый обмен." />
      </Flow>
    </ScrollToTop>
  )
})
