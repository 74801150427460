import { FC, useCallback, useState } from 'react'
import ReactPlayer from 'react-player'
import { observer } from 'mobx-react-lite'
import { Flow } from '@src/components/common/flow'
import { core, ScreenMode } from '@src/core'
import { Breadcrumbs } from '@src/components/common/breadcrumbs'
import { LINKS } from '@src/routes'
import { Title } from '@platform/ui'
import { SectionH2, Section } from '@src/components/common/section'
import { CategorySwiper } from '@src/container/category-swiper'
import { Button, Icon, Modal } from '@platform/ui'
import { useScreenModeValue } from '@src/hooks'
import { ScrollToTop } from '@src/components/common/scrollToTop'

import { dealsMap, artboardMap, servicesMap, howStartWorkProcessStepsMap } from '@src/pages/home/constants'
import { InfoCard } from '@src/pages/home/components/info-card'
import { ServiceCard } from '@src/pages/home/components/service-card'
import { Contatcs } from '@src/pages/home/container/contacts'
import { ProcessStep } from '@src/pages/home/components/process-step'

import ProcessLogo from '@src/images/process-logo.png'
import Farmer1 from './images/about-us-farmer1.png'
import Farmer2 from './images/about-us-farmer2.png'
import AboutUsBackgroundDesktop from './images/about-us-background-desktop.png'
import AboutUsBackgroundTablet from './images/about-us-background-tablet.png'
import { ReactComponent as ArrowSvg } from './images/arrow.svg'

const { layoutService } = core
const { screen } = layoutService

const isServer = process.env.APP_ENV === 'server'

export const AboutUs: FC = observer(() => {
  const [modalState, setModalState] = useState(false)

  const openModal = useCallback(() => setModalState(true), [])
  const closeModal = useCallback(() => setModalState(false), [])

  const styles = useScreenModeValue({
    desktop: {
      backgroundImage: `url(${AboutUsBackgroundDesktop})`,
      backgroundPosition: 'calc(50% + 600px) 0',
    },
    tablet: {
      backgroundImage: `url(${AboutUsBackgroundTablet})`,
      backgroundPosition: 'calc(50% + 500px) 0',
    },
    mobile: {},
  })

  return (
    <ScrollToTop>
      <Flow directory="col" className="py-7" full>
        <div className="w-full bg-no-repeat" style={styles}>
          <Flow directory="col" className="content-main pb-0 md:pb-200px lg:pb-380px" space={10}>
            <Breadcrumbs items={[{ children: 'Главная', href: LINKS.HOME }, { children: 'О нас' }]} />
            <Title
              tag="h1"
              color="neutral"
              colorNumber={900}
              weight="semibold"
              size={screen.mode === ScreenMode.mobile ? '1xl' : '2xl'}
              className="w-full md:w-3/4 lg:w-1/2"
            >
              Проект для аграриев, который позволяет проводить все виды сделок по сельскохозяйственной продукции в одном
              месте.
            </Title>
            <Flow space={screen.mode === ScreenMode.mobile ? 4 : 8}>
              <Button
                shape="circle"
                color="primary"
                className="w-92px h-92px md:w-128px md:h-128px shadow-2xl"
                onClick={openModal}
              >
                <Icon.Play color="white" size="2xl" />
              </Button>
              <Flow directory="col" className="pt-0 md:pt-4" space={3}>
                <span className="font-medium text:sm md:text-md">
                  Посмотрите видео и узнайте всё
                  {screen.mode !== ScreenMode.mobile && <br />}о нашей платформе за 1 минуту.
                </span>
                <ArrowSvg />
              </Flow>
            </Flow>
          </Flow>
        </div>
        <Flow directory="col" space={'88px'} className="content-main">
          <Section>
            <CategorySwiper customTitle="Все категории сельскохозяйственных товаров на одной площадке" />
          </Section>
          <SectionH2 title="Доступны все виды сделок">
            <Flow className="justify-center md:justify-between flex-wrap gap-y-10 px-2 md:px-8 lg:px-0">
              {dealsMap.map((item, index) => (
                <InfoCard
                  key={index}
                  {...item}
                  descriptionClassName="text-xs md:text-sm"
                  className="max-w-280px text-center"
                />
              ))}
            </Flow>
          </SectionH2>
          <Section className="flex items-start lg:items-center flex-col md:flex-row w-full">
            <Flow directory="col" space={4} className="w-full md:w-300px">
              <Title
                color="neutral"
                colorNumber={900}
                weight="medium"
                tag="h2"
                size={screen.mode === ScreenMode.mobile ? 'lg' : '1xl'}
              >
                Работа с оптом и розницей
              </Title>
              <span>Участвуйте в аукционах и покупайте товары на лучших для вас условиях!</span>
            </Flow>
            <img src={Farmer1} className="ml-0 lg:ml-100px w-full md:w-1/2 lg:w-auto h-full" />
          </Section>
          <Section className="flex items-start lg:items-center flex-col-reverse md:flex-row w-full justify-around">
            <img src={Farmer2} className="ml-0 lg:ml-100px w-full md:w-1/2 lg:w-auto h-full" />
            <Flow directory="col" space={4} className="w-full md:w-300px">
              <Title
                color="neutral"
                colorNumber={900}
                weight="medium"
                tag="h2"
                size={screen.mode === ScreenMode.mobile ? 'lg' : '1xl'}
              >
                Проведение безопасных сделок онлайн
              </Title>
              <span>Воспользуйтесь безопасной сделкой, чтобы максимально обезопасить себя от рисков.</span>
            </Flow>
          </Section>
          <SectionH2 titleWrapperClassName="w-full justify-center" title="Собственные страницы для каждого продавца">
            <Flow className="justify-around flex-wrap gap-y-10 px-0 md:px-20" full>
              {artboardMap.map((item, index) => (
                <InfoCard
                  key={index}
                  {...item}
                  descriptionClassName="font-medium"
                  className="max-w-144px text-center"
                />
              ))}
            </Flow>
          </SectionH2>
          <SectionH2 title="Широкий спектр услуг продвижения">
            <Flow className="flex-wrap justify-between lg:justify-start gap-5">
              {servicesMap.map((item, index) => (
                <ServiceCard key={index} {...item} />
              ))}
            </Flow>
          </SectionH2>
          <SectionH2 title="Как начать работу с платформой">
            <Flow>
              <Flow directory="col" space={6} className="w-full md:w-1/2">
                {howStartWorkProcessStepsMap.map((item, index) => (
                  <ProcessStep key={index} {...item} index={index + 1} />
                ))}
              </Flow>
              {screen.mode !== ScreenMode.mobile && (
                <div className="w-1/2">
                  <img src={ProcessLogo} alt="Как начать работу с платформой" />
                </div>
              )}
            </Flow>
          </SectionH2>
          <SectionH2 title="Контакты">
            <Contatcs />
          </SectionH2>
        </Flow>
        {!isServer && (
          <Modal isOpen={modalState} onClose={closeModal}>
            <Modal.Header>
              <div className="flex justify-end">
                <Button variant="link" className="text-xss" onClick={closeModal}>
                  <Icon.Close size="lg" />
                </Button>
              </div>
            </Modal.Header>
            <Modal.Body>
              <ReactPlayer url="https://vimeo.com/836096057" controls />
            </Modal.Body>
            <Modal.Footer />
          </Modal>
        )}
      </Flow>
    </ScrollToTop>
  )
})

AboutUs.displayName = 'AboutUsPage'
