import { FC } from 'react'
import { Flow } from '@src/components/common/flow'
import { ServiceCardProps } from './types'
import { Title } from '@platform/ui'

export const ServiceCard: FC<ServiceCardProps> = ({ imgSrc, title, description }) => {
  return (
    <Flow directory="col" align="start" space={4} className="w-345px">
      <img src={imgSrc} alt={title} className="w-full" />
      <Title color="neutral" colorNumber={900} weight="medium" className="text-sm md:text-md">
        {title}
      </Title>
      <span className="">{description}</span>
    </Flow>
  )
}
