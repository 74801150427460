import { LINKS } from '@src/routes'

import { InfoCardProps } from './components/info-card'
import { ServiceCardProps } from './components/service-card'
import { StepsWorkProps } from './components/steps-work'
import { BusinessInfoCardProps } from './components/business-info-card'
import { StepCardProps } from './components/step-card'

import auctionImg from './images/auction.png'
import exchangeImg from './images/exchange.png'
import sellImg from './images/sell.png'
import buyImg from './images/buy.png'

import artboard1Img from './images/artboard1.png'
import artboard2Img from './images/artboard2.png'
import artboard3Img from './images/artboard3.png'
import artboard4Img from './images/artboard4.png'
import artboard5Img from './images/artboard5.png'

import service13Img from './images/service1.png'
import service2Img from './images/service2.png'
import service3Img from './images/service3.png'

import opportunityBetImg from './images/opportunity-bet.png'
import opportunityControlImg from './images/opportunity-control.png'
import opportunityDescriptionImg from './images/opportunity-description.png'
import opportunityNotificationImg from './images/opportunity-notification.png'
import opportunitySafetyImg from './images/opportunity-safety.png'
import opportunityTransparencyImg from './images/opportunity-transparency.png'

import business1Img from './images/business1.png'
import business2Img from './images/business2.png'
import business3Img from './images/business3.png'
import business4Img from './images/business4.png'
import { ProcessStepProps } from './components/process-step'

export const dealsMap: Pick<InfoCardProps, 'title' | 'titlePathTo' | 'description' | 'pathTo' | 'imgSrc'>[] = [
  {
    title: 'Аукцион',
    description: 'Участвуйте в аукционах и покупайте товары на лучших для вас условиях!',
    pathTo: LINKS.AUCTION,
    titlePathTo: 'Подробнее об аукционе',
    imgSrc: auctionImg,
  },
  {
    title: 'Обмен',
    description: 'Обменивайте ваши товары на товары других продавцов!',
    pathTo: LINKS.EXCHANGE,
    titlePathTo: 'Подробнее об обмене',
    imgSrc: exchangeImg,
  },
  {
    title: 'Продажа',
    description: 'Размещайте ваши товары на продажу и привлекайте новых покупателей.',
    imgSrc: sellImg,
  },
  {
    title: 'Покупка',
    description: 'Выбирайте среди предложений наиболее подходящие для вас.',
    imgSrc: buyImg,
  },
]

export const artboardMap: Pick<InfoCardProps, 'description' | 'imgSrc'>[] = [
  {
    description: 'Создайте свой интернет-магазин',
    imgSrc: artboard1Img,
  },
  {
    description: 'Размещайте рекламные баннеры',
    imgSrc: artboard2Img,
  },
  {
    description: 'Принимайте заказы и проводите безопасные сделки',
    imgSrc: artboard3Img,
  },
  {
    description: 'Разместите информацию о своей компании и товарах',
    imgSrc: artboard4Img,
  },
  {
    description: 'Увеличьте кол-во отзывов о своей компании',
    imgSrc: artboard5Img,
  },
]

export const servicesMap: ServiceCardProps[] = [
  {
    imgSrc: service13Img,
    description: 'Закрепляйте ваши объявления в самом верху и получайте больше просмотров.',
    title: 'Топовые позиции',
  },
  {
    imgSrc: service2Img,
    description: 'Выделяйте цветом свои объявления и привлекайте к ним больше внимания.',
    title: 'Выделение цветом',
  },
  {
    imgSrc: service3Img,
    description: 'У вашего объявления не растёт кол-во просмотров!? Значит, нужно его поднять!',
    title: 'Автоподнятия',
  },
]

export const opportunityAuctionMap: Pick<InfoCardProps, 'title' | 'description' | 'imgSrc'>[] = [
  {
    imgSrc: opportunityBetImg,
    title: 'Автоматические ставки',
    description:
      'Вы можете указать свою максимальную цену, а бот автоматически сделает минимальную ставку каждый раз, когда ваша ставка была перебита.',
  },
  {
    imgSrc: opportunityTransparencyImg,
    title: 'Прозрачность аукционов',
    description: 'На странице аукциона вы можете просматривать всю историю ставок: кем, какая и когда была сделана.',
  },
  {
    imgSrc: opportunityControlImg,
    title: 'Управление лотами',
    description: 'Всеми аукционами, в которых вы участвуете, можно управлять в одном месте, в личном кабинете.',
  },
  {
    imgSrc: opportunityNotificationImg,
    title: 'Уведомления',
    description:
      'Оставайтесь в курсе всех важных уведомлений об изменении аукциона, например, если ваша ставка перебита или аукцион подходит к концу и другие.',
  },
]

export const opportunityExchangeMap: Pick<InfoCardProps, 'title' | 'description' | 'imgSrc'>[] = [
  {
    imgSrc: opportunityDescriptionImg,
    title: 'Подробное описание',
    description: 'В каждом объявлении типа обмен можно найти информацию о доставке, характеристиках товара и другую.',
  },
  {
    imgSrc: opportunitySafetyImg,
    title: 'Безопасная сделка',
    description: 'Опция «Безопасная сделка» позволяет сделать процесс обмена товаров максимально удобным и безопасным.',
  },
  {
    imgSrc: opportunityControlImg,
    title: 'Управление обменами',
    description: 'Всеми обменами, в которых вы участвуете, можно управлять в одном месте, в личном кабинете.',
  },
  {
    imgSrc: opportunityNotificationImg,
    title: 'Уведомления',
    description: 'Оставайтесь в курсе всех важных уведомлений об изменении обменов, в которых вы участвуете.',
  },
]

export const stepsWorksAuctionMap: StepsWorkProps['steps'] = [
  'Чтобы принять участие в аукционе, сперва нужно его найти! Для этого можно воспользоваться каталогом или поиском.',
  'Перед тем, как оставить ставку, внимательно ознакомьтесь с описанием и характеристиками товара.',
]

export const stepsWorkExchangeMap: StepsWorkProps['steps'] = [
  'Чтобы принять участие в обмене, сперва нужно его найти! Для этого можно воспользоваться каталогом или поиском.',
  'Перед тем, как сделать предложение на обмен, внимательно ознакомьтесь с описанием и характеристиками товара.',
]

export const businessAdvantagesMap: BusinessInfoCardProps[] = [
  {
    title: 'Все виды сделок сделок онлайн',
    itemList: ['Аукцион', 'Обмен', 'Продажа', 'Покупка'],
    imgSrc: business1Img,
  },
  {
    title: 'Контролируемый процесс сделки от заявки до доставки на склад',
    itemList: [
      'Управление всеми сделками через личный кабинет.',
      'Функционал безопасной сделки для гарантии завершения всех этапов без потерь и рисков.',
      'Электронный документооборот.',
    ],
    imgSrc: business2Img,
  },
  {
    title: 'Инструменты автоматизации бизнес-процессов',
    itemList: [
      'Автоматическое создание объявления на грузоперевозку.',
      'Бот для автоматического участия в аукционе.',
      'Бот, который сам ищет нужные вам товары по вашим требованиям. ',
    ],
    imgSrc: business3Img,
  },
  {
    title: 'Эффективная реклама для увеличения продаж ',
    itemList: [
      'Собственный интернет-магазин с фильтрами, корзиной и уникальным дизайном.',
      'Широкий спектр услуг продвижения ваших товаров.',
      'Обширный набор маркетинговых средств через скидки, спецпредложения и лояльность.',
    ],
    imgSrc: business4Img,
  },
]

export const businessStepsMap: Pick<StepCardProps, 'title' | 'description' | 'videoTitle'>[] = [
  {
    title: 'Пройдите регистрацию',
    description: [
      'Зарегистрируйтесь на ТЫ МНЕ Я ТЕБЕ с помощью телефона либо почты или с помощью социальной сети.',
      'Заполните юридическую информацию о вашей компании.',
    ],
    videoTitle: 'Пошаговая инструкция по регистрации',
  },
  {
    title: 'Создайте объявления',
    description:
      'Добавьте объявления о продаже ваших товаров, чтобы они стали видны всем на ТЫ МНЕ Я ТЕБЕ! А дальше просто дожидайтесь заказов.',
    videoTitle: 'Пошаговая инструкция по созданию объявления',
  },
  {
    title: 'Начинайте продавать',
    description:
      'Принимайте заказы, общайтесь и договаривайтесь с покупателями, подписывайте договора и принимайте оплату через безопасную сделку. Также не забывайте обмениваться отзывами!',
    videoTitle: 'Как работает безопасная сделка?',
  },
]

export const howStartWorkProcessStepsMap: Omit<ProcessStepProps, 'index'>[] = [
  {
    title: 'Зарегистрируйтесь',
    titleButton: 'Создать объявление',
    pathTo: LINKS.CREATE_PRODUCT,
  },
  {
    title:
      'Воспользуйтесь каталогом, чтобы найти интересующие вас товары. Либо разместите своё первое объявление и получите заказ.',
    titleButton: 'Перейти в каталог',
    pathTo: LINKS.CATEGORY_HOME,
  },
  {
    title: 'Быстро и без рисков заключайте сделки с помощью безопасной сделки на нашей платформе.',
    titleButton: 'Подробнее',
    pathTo: '/',
  },
]
export const dealProcessStepsMap: Omit<ProcessStepProps, 'index'>[] = [
  {
    title: 'Создание собственного объявления для получения новых заказов.',
    titleButton: 'Создать объявление',
    pathTo: LINKS.CREATE_PRODUCT,
  },
  {
    title: 'Коммуникация с покупателем и обсуждение всех деталей сделки.',
  },
  {
    title: 'Подписание договора, выставление счёта и оплата товара через безопасную сделку внутри платформы.',
  },
  {
    title: 'Отгрузка товара',
  },
]
