import { FC } from 'react'
import { Button, Icon } from '@platform/ui'
import { Link } from 'react-router-dom'

import { ButtonLinkProps } from './types'

export const ButtonLink: FC<ButtonLinkProps> = ({ pathTo, title }) => {
  return (
    <Link to={pathTo}>
      <Button
        variant="link"
        color="primary"
        className="underline font-normal"
        textClassName="text-xs md:text-sm"
        iconPosition="right"
        icon={<Icon.ArrowLong position="right" />}
      >
        {title}
      </Button>
    </Link>
  )
}
