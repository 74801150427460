import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, Title, Swiper, SwiperSlide, Grid, GridCell } from '@platform/ui'

import { LINKS } from '@src/routes'
import { core, ScreenMode } from '@src/core'
import { Flow } from '@src/components/common/flow'
import { SectionH2, Section } from '@src/components/common/section'
import { Breadcrumbs } from '@src/components/common/breadcrumbs'
import { CategorySwiper } from '@src/container/category-swiper'
import { ServiceCard } from '@src/pages/home/components/service-card'
import { useScreenModeValue, useNavigationApp } from '@src/hooks'
import {
  artboardMap,
  dealsMap,
  servicesMap,
  businessAdvantagesMap,
  businessStepsMap,
  dealProcessStepsMap,
} from '@src/pages/home/constants'
import { InfoCard } from '@src/pages/home/components/info-card'
import { Promo } from '@src/pages/home/components/promo'
import { BusinessInfoCard } from '@src/pages/home/components/business-info-card'
import { StepCard } from '@src/pages/home/components/step-card'
import { ProcessStep } from '@src/pages/home/components/process-step'
import { ScrollToTop } from '@src/components/common/scrollToTop'

import ForBusinessLogo from '@src/images/for-business-logo.png'
import ProcessLogo from '@src/images/process-logo.png'

const { layoutService } = core
const { screen } = layoutService

export const ForBusiness: FC = observer(() => {
  const handleToCatalog = useNavigationApp({ to: LINKS.CATEGORY_HOME })

  const width = useScreenModeValue({ desktop: 2, tablet: 4, mobile: 8 })

  return (
    <ScrollToTop>
      <Flow directory="col" space={'88px'} className="content-main py-7">
        <Flow>
          <Flow directory="col" space={10.5} className="w-full md:pr-4 lg:pr-0 md:w-1/2" align="start">
            <Breadcrumbs items={[{ children: 'Главная', href: LINKS.HOME }, { children: 'Для бизнеса' }]} />
            <Flow directory="col" space={5}>
              <Title
                tag="h1"
                color="neutral"
                colorNumber={900}
                weight="semibold"
                size={screen.mode === ScreenMode.mobile ? '1xl' : '2xl'}
              >
                Решайте комплексные бизнес-задачи по сбыту и закупке товаров с возможностями ТЫ МНЕ Я ТЕБЕ.
              </Title>
              <span className="font-medium text:sm md:text-md">
                Продавайте и покупайте товары через аукцион на лучших для вас условиях. Или воспользуйтесь обменом! Об
                этих и о многих других инструментах вы узнаете на этой странице, чтобы легко и быстро стартовать на ТЫ
                МНЕ Я ТЕБЕ.
              </span>
            </Flow>
            <Button color="primary" size="xl" className="max-w-345px w-full" onClick={handleToCatalog}>
              Перейти в каталог
            </Button>
          </Flow>
          {screen.mode !== ScreenMode.mobile && (
            <div className="w-1/2">
              <img src={ForBusinessLogo} alt="Для бизнеса" />
            </div>
          )}
        </Flow>
        <SectionH2 title="Бизнес на ТЫ МНЕ Я ТЕБЕ - это">
          <Grid gap={5}>
            {businessAdvantagesMap.map((item, key) => (
              <GridCell key={key} width={width}>
                <BusinessInfoCard key={key} {...item} />
              </GridCell>
            ))}
          </Grid>
        </SectionH2>
        <SectionH2 title="Процесс сделки">
          <Flow>
            <Flow directory="col" space={6} className="w-full md:w-1/2">
              {dealProcessStepsMap.map((item, index) => (
                <ProcessStep key={index} {...item} index={index + 1} />
              ))}
            </Flow>
            {screen.mode !== ScreenMode.mobile && (
              <div className="w-1/2">
                <img src={ProcessLogo} alt="Как начать работу с платформой" />
              </div>
            )}
          </Flow>
        </SectionH2>
        <Section>
          <CategorySwiper customTitle="Все категории сельскохозяйственных товаров на одной площадке" />
        </Section>
        <SectionH2 title="Доступны все виды сделок">
          <Flow className="justify-center md:justify-between flex-wrap gap-y-10 px-2 md:px-8 lg:px-0">
            {dealsMap.map((item, index) => (
              <InfoCard
                key={index}
                {...item}
                descriptionClassName="text-xs md:text-sm"
                className="max-w-280px text-center"
              />
            ))}
          </Flow>
        </SectionH2>
        <SectionH2 titleWrapperClassName="w-full justify-center" title="Собственные страницы для каждого продавца">
          <Flow className="justify-around flex-wrap gap-y-10 px-0 md:px-20" full>
            {artboardMap.map((item, index) => (
              <InfoCard key={index} {...item} descriptionClassName="font-medium" className="max-w-144px text-center" />
            ))}
          </Flow>
        </SectionH2>
        <SectionH2 title="Широкий спектр услуг продвижения">
          <Flow className="flex-wrap justify-between lg:justify-start gap-5">
            {servicesMap.map((item, index) => (
              <ServiceCard key={index} {...item} />
            ))}
          </Flow>
        </SectionH2>
        <Swiper arrowPosition={screen.mode === ScreenMode.desktop ? 'inner' : 'bottom'}>
          {businessStepsMap.map((item, key) => (
            <SwiperSlide key={key} className="p-3 w-304px md:w-710px">
              <StepCard {...item} step={key + 1} allSteps={businessStepsMap.length} />
            </SwiperSlide>
          ))}
        </Swiper>
        <Promo onClick={handleToCatalog} title="Всё ещё думаете? Перейдите в каталог и найдите свой первый аукцион." />
      </Flow>
    </ScrollToTop>
  )
})
