import { FC, useCallback } from 'react'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import { Flow } from '@src/components/common/flow'
import { Button } from '@platform/ui'

import { ProcessStepProps } from './types'

export const ProcessStep: FC<ProcessStepProps> = ({ title, titleButton, pathTo, index }) => {
  const navigate = useNavigate()

  const handleClick = useCallback(() => {
    if (pathTo) navigate(pathTo)
  }, [pathTo])

  return (
    <div
      className={cn('flex flex-col md:flex-row gap-x-7 gap-y-2.5', {
        'items-start': titleButton,
        'items-center': !titleButton,
      })}
    >
      <div className="flex flex-col items-center md:items-start w-full md:w-auto">
        <div className="w-54px h-54px rounded-full bg-primary-100 text-1xl text-primary-500 flex flex-static items-center justify-center font-medium">
          {index}
        </div>
      </div>
      <Flow directory="col" space={4} className="items-center md:items-start w-full md:w-auto">
        <span className="font-medium text-sm md:text-md text-center md:text-left">{title}</span>
        {titleButton && pathTo && (
          <Button size="md" className="rounded-xl" color="positive" onClick={handleClick}>
            {titleButton}
          </Button>
        )}
      </Flow>
    </div>
  )
}
