import { Swiper, SwiperSlide } from '@platform/ui'
import { Flow } from '@src/components/common/flow'
import { FC } from 'react'
import PhoneImg from '../../images/phone.png'

import { StepsWorkProps } from './types'

export const StepsWork: FC<StepsWorkProps> = ({ steps }) => {
  return (
    <Swiper>
      {steps.map((item, index) => (
        <SwiperSlide key={index} className="w-full lg:w-auto xl:w-1/2 mr-4">
          <div className="flex flex-col md:flex-row items-center md:space-x-14 space-y-4 md:space-y-0">
            <Flow className="px-8 py-7 shadow-xl w-full max-w-345px" directory="col" align="start">
              <span className="font-medium text-sm md:text-md">{`Шаг ${index + 1}`}</span>
              <span className="text-xs md:text-sm">{item}</span>
            </Flow>
            <img src={PhoneImg} />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
