import { FC } from 'react'
import { Grid, GridCell, Icon } from '@platform/ui'
import { Flow } from '@src/components/common/flow'
import { observer } from 'mobx-react-lite'
import { useScreenModeValue } from '@src/hooks'

export const Contatcs: FC = observer(() => {
  const width = useScreenModeValue({
    desktop: 3,
    tablet: 4,
    mobile: 1,
  })

  const columnsNumber = useScreenModeValue({
    desktop: 12,
    tablet: 12,
    mobile: 1,
  })

  return (
    <Grid columnsNumber={columnsNumber}>
      <GridCell width={width}>
        <Flow directory="col" space={3.5}>
          <span className="text-neutral-400">Телефон</span>
          <span className="text-black font-medium text-md">+7 (999) 999-99-99</span>
        </Flow>
      </GridCell>
      <GridCell width={width}>
        <Flow directory="col" space={3.5}>
          <span className="text-neutral-400">E-mail</span>
          <span className="text-black font-medium text-md">company@gmail.com</span>
        </Flow>
      </GridCell>
      <GridCell width={width}>
        <Flow directory="col" space={3.5}>
          <span className="text-neutral-400">Адрес главного офиса</span>
          <span className="flex items-start space-x-1">
            <span className="pt-1">
              <Icon.Location size="md" color="primary" />
            </span>
            <span className="text-black font-medium text-md"> Ветошный переулок 13, г. Москва</span>
          </span>
        </Flow>
      </GridCell>
    </Grid>
  )
})
