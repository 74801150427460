import { Title, List, ListItem, Button, Icon } from '@platform/ui'
import { Flow } from '@src/components/common/flow'
import { FC } from 'react'

import { StepCardProps } from './types'

export const StepCard: FC<StepCardProps> = ({ step, allSteps, title, videoTitle, description }) => {
  return (
    <div className="w-full flex space-x-0 md:space-x-5 flex-col md:flex-row px-18px md:px-8 pt-4 md:pt-7 pb-0 md:pb-9 rounded-xl shadow-xl h-380px md:h-345px">
      <div className="flex flex-col w-full md:w-1/2">
        <span className="font-medium text-sm md:text-md">{`Шаг ${step}`}</span>
        <Title
          colorNumber={900}
          weight="medium"
          textClassName="text-lg md:text-1xl"
          className="mt-2 md:mt-2.5 mb-18px md:mb-8"
        >
          {title}
        </Title>
        {Array.isArray(description) ? (
          <List className="list-inside list-disc marker:primary-500">
            {description.map((item, key) => (
              <ListItem className="text-primary-500" key={key}>
                <span className="text-neutral-900 text-xs md:text-sm">{item}</span>
              </ListItem>
            ))}
          </List>
        ) : (
          <span className="text-neutral-900 text-xs md:text-sm">{description}</span>
        )}
        <Flow align="end" className="hidden md:flex h-full">
          {`${step}/${allSteps}`}
        </Flow>
      </div>
      <Flow className="h-full w-full md:w-1/2 mt-5">
        <Flow directory="col" className="bg-primary-100 w-full h-142px md:h-280px rounded-xl">
          <div className="flex justify-center items-center h-full w-full">
            <Button shape="circle" color="primary" className="w-44px h-44px md:w-92px md:h-92px shadow-2xl">
              <Icon.Play color="white" size="1xl" />
            </Button>
          </div>
          <Flow align="end" className="pb-4 px-4">
            <span className="font-medium text-xs md:text-sm">{videoTitle}</span>
          </Flow>
        </Flow>
      </Flow>
    </div>
  )
}
